import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {toPlainText} from '../lib/helpers'

import SummerWinterSChoolsSingle from '../components/summer-winter-schools-single'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query SummerWinterSchoolsTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      heroImage{
        ...SanityImage
        alt
      }
      title
      subTitle
      _rawIntro
      _rawFooter
      description
      keywords
    }

    post: sanitySummerWinterSchools(id: {eq: $id}) {
      id
      publishedAt
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      categories{
        _id
        title
        catParent
        slug{
          current
        }
      }
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody(resolveReferences: {maxDepth: 5})
    }
  }
`

const SummerWinterSchoolsTemplate = props => {
  const {data, errors} = props
  const post = data && data.post
  const site = data && data.site
  return (
    <Layout>

      {errors && <SEO title='GraphQL Error' />}
      {post &&
      <SEO
        title={post.title || 'Untitled'}
        description={post._rawExcerpt && toPlainText(post._rawExcerpt)}
        image={post.mainImage || site.heroImage}
      />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {post && <SummerWinterSChoolsSingle {...post} />}
    </Layout>
  )
}

export default SummerWinterSchoolsTemplate
